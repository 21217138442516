
(function() {
    var throttle = function(type, name, obj) {
        var obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
            requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };
    throttle ("scroll", "optimizedScroll");
})();

var ensineAprenda = document.getElementById("img-ea");

window.addEventListener("optimizedScroll", function() {
    ensineAprenda.style.transform = "rotate("+window.pageYOffset+"deg)";
});

/*
$( window ).scroll(function(e) {
    console.log(e);
});
*/


function digitando() {

    var resolucao = 'RESOLUÇÃO:<br>';
    resolucao += 'Como Adam Smith escreveu<br>';
    resolucao += 'em seu livro "A Riqueza<br>';
    resolucao += 'das Nações", o capitalismo...<br>';

    $("#digitando-resolucao").typed({
        strings     : [resolucao, "Droga, apaguei sem querer."],
        typeSpeed   : 30,
        backSpeed   : -50,
        loop        : true,
        backDelay   : 1500,
        showCursor  : false
    });

}

var configGeral = {
    reset       : true,
    duration    : 1000,
    viewFactor  : 0.6
};

window.sr = ScrollReveal( configGeral );

var vemDaDireita = {origin: 'right', distance: '200px'};
var vemDaEsquerda = {origin: 'left', distance: '200px'};

// div cabeca
sr.reveal('#logo-home,#wrap-cover-video', {viewFactor: 0.2, scale: 0.6, reset: false});

// div ENSINE-APRENDA
sr.reveal('#conteudo-ensine-aprenda,.botao-registrar');

sr.reveal('.vem-da-esquerda', vemDaEsquerda);
sr.reveal('.vem-da-direita', vemDaDireita);

// div RESOLVA
// Mostra cada div da questao (RESOLVA) com intervalo
sr.reveal('.questao-no-note', {delay: 800}, 600);

// div ELABORE
sr.reveal('#elaborando-resolucao', {origin: 'right', distance: '200px', afterReveal: digitando});

// div PREMIOS
sr.reveal('#podio', vemDaEsquerda);
sr.reveal('#guri', {origin: 'bottom', delay: 800});



